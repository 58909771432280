class NodeParser {

	parseFunction( /*source*/ ) {

		console.warn( 'Abstract function.' );

	}

}

export default NodeParser;
